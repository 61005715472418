import { getSalesId } from './general';

import request from 'src/utils/request';
import { CONNECT } from 'src/utils/environment';

export function getLodgingSupplier ({salesId, supplierId, arrivalDate, departureDate, adults, children}) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId(salesId);

    request({
      url: `${CONNECT}/lodging/supplier`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        supplierId,
        arrivalDate,
        departureDate,
        adults,
        children,
      }
    })
    .then((response) => {
      const data = response.data || null;

      if (data.result) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getLodgingSupplier', err);
      reject(err)
    });
  })
};

export function getLodgingAvailability ({salesId, supplierId, productId, month, year}) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId(salesId);

    request({
      url: `${CONNECT}/inntopia/lodging/supplier/room/availability`,
      method: 'GET',
      params: {
        salesId: inntopiaSalesId,
        supplierId,
        productId,
        startMonth: month,
        startYear: year,
      }
    })
    .then((response) => {
      const data = response.data || null;

      if (data.SupplierID) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getLodgingAvailability', err);
      reject(err)
    });
  })
};