module.exports = {
  "defaultLanguage": "nb-NO",
  "languages": [
    {
      "code": "nb-NO",
      "name": "Norsk",
      "icon": "1b541bed-cb79-4a96-b514-ab142f07cf6b.svg"
    },
    {
      "code": "en-US",
      "name": "English",
      "icon": "53347e14-c4eb-4336-a950-6cc51cadc23b.svg"
    }
  ]
}