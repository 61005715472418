import { getSalesId } from './general';

import request from 'src/utils/request';
import { CONNECT } from 'src/utils/environment';
import { getSession } from 'src/utils/localStorage';

export function getTotalDueNow () {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/inntopia/cart/depositSchedule`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
        bundle: 1,
        group: 1,
      }
    })
    .then((response) => {
      const data = response.data || null;

      resolve(data);
    })
    .catch(err => {
      console.log('error totalDueNow/getTotalDueNow', err);
      reject(err)
    });
  })
};