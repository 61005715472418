import { getSalesId } from './general';

import request from 'src/utils/request';
import { CONNECT } from 'src/utils/environment';
import { getSession } from 'src/utils/localStorage';

export function getCustomerFaf () {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/inntopia/customer/friends-family`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
      }
    })
    .then((response) => {
      const data = response.data || null;

      if (data) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getCustomerFaf', err);
      reject(err)
    });
  })
};

export function addCustomerFaf ({ firstName, lastName }) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/inntopia/customer/friends-family/add`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
        firstName,
        lastName
      }
    })
    .then((response) => {
      const data = response.data || null;

      if (data.Success) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/addCustomerFaf', err);
      reject(err)
    });
  })
};

export function updateCustomerFaf (requirements) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    const personId = requirements.find(requirement => requirement.InputType === 3)?.RequirementsValue;
    if (!personId) resolve();

    const customerRequirements = requirements.map(req => {
      return {
        PersonId: personId,
        RequirementsCode: req.RequirementsCode,
        RequirementsValue: req.RequirementsValue
      }
    })
    request({
      url: `${CONNECT}/inntopia/customer/friends-family/update-customer-requirements-faf`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
        customerRequirements
      }
    })
    .then((response) => {
      const data = response.data || null;

      resolve(data);
    })
    .catch(err => {
      console.log('error service/updateCustomerFaf', err);
      reject(err)
    });
  })
};