import { getSalesId } from './general';

import request from 'src/utils/request';
import { CONNECT } from 'src/utils/environment';

export function getPackageComponent ({salesId, packageId, startDate, endDate, adults, children, componentId, componentDate}) {
  const inntopiaSalesId = getSalesId(salesId);

  let parameters = {
    id: inntopiaSalesId,
    salesId: inntopiaSalesId,
    packageId,
    startDate,
    endDate,
    adultCount: adults,
    childCount: children,
    onlyComponent: componentId,
  };

  if (componentDate) {
    parameters.componentDate = componentDate;
  }

  return new Promise((resolve, reject) => {
    request({
      url: `${CONNECT}/packages/package`,
      method: 'GET',
      params: parameters
    })
    .then((response) => {
      const data = response.data || null;

      if (data.result) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getPackageComponent', err);
      reject(err)
    });
  })
};
