import { CONNECT_CLIENT } from "./definitions";
import { buildAnalytics4Products, getTotalRevenue, getTotalTaxes } from "./gtmInntopia";
 
 const buildGoogleTagManagerProducts = (event, prods, section = false) => {
   let gtmProducts = [];
   prods &&
     prods.forEach((prod, index) => {
       let gtmProduct = {};
       Object.keys(prod).forEach(key => {
         if (key === "name" || key === "product_name") gtmProduct.item_name = prod[key];
         if (key === "id" || key === "uuid" || key === "product_id") gtmProduct.item_id = prod[key];
         if (key === "unit_price" || key === "unitPrice") gtmProduct.price = prod[key];
         if (key === "price") {
          // gausta e-commerce assignations
          if (Array.isArray(prod.price)) {
            gtmProduct.price = prod.price[0].amount;
            gtmProduct.currency = prod.price[0].currency;
            gtmProduct.item_variant = prod.price[0].price_name
          } else {
            gtmProduct.price = prod.price.amount || prod.price.price_value;
            gtmProduct.currency = prod.price.currency;
            gtmProduct.item_variant = prod.price.price_name
          }
         }
         if (key === "unit_price_currency" || key === "currency") gtmProduct.currency = prod[key];
         if (key === "variant" || key === "product_type" || key === "type") gtmProduct.item_variant = prod[key];
 
         if (key === "productCategory" && prod.productCategory.name) gtmProduct.item_category = prod.productCategory.name
         if (key === "category") gtmProduct.item_category = prod[key];
         
         if (key === "supplier" && prod.supplier?.legalName) gtmProduct.item_brand = prod.supplier.legalName
       })
 
       if (section) gtmProduct.list_name = section;
       if (
         event === "view_item_list" ||
         event === "select_item" ||
         event === "view_item"
       ) {
         gtmProduct.index = index;
       }
 
       gtmProducts.push(gtmProduct);
     });
 
   return gtmProducts;
 };
 
 const getRevenue = (prods) => {
   let revenue = 0;
   prods.forEach((prod) => (revenue += prod.quantity * (prod.unitPrice ? prod.unitPrice : 0)));
   return revenue;
 };
 
 const getTaxes = (prods) => {
   let taxes = 0;
   prods.forEach((prod) => (taxes += prod.quantity * (prod.taxAmount ? prod.taxAmount : 0)));
   return taxes;
 };

 const getCurrency = (prods) => {
  let currency = "";
  if (prods && prods[0] && prods[0].currency) currency = prods[0].currency;
  if (prods && prods[0] && prods[0].Currency) currency = prods[0].Currency;
  if (prods && prods[0] && prods[0].CurrencyAware_Price && prods[0].CurrencyAware_Price.BaseCurrencyCode) currency = prods[0].CurrencyAware_Price.BaseCurrencyCode;
  return currency;
 }
 
 export const gtmFunction = (event, data, section = false, integration = "") => {
  let gtmObject = {};
  switch (event) {
    case "view_item_list":
      gtmObject = {
        event,
        ecommerce: {
          items: integration === "inntopia" ? buildAnalytics4Products(data, section, event) : buildGoogleTagManagerProducts(event, data, section),
        },
      };
      break;
    case "select_item":
      gtmObject = {
        event,
        ecommerce: {
          items: integration === "inntopia" ? buildAnalytics4Products(data, section, event) : buildGoogleTagManagerProducts(event, data),
        },
      };
      break;
    case "view_item":
      gtmObject = {
        event,
        ecommerce: {
          value: getTotalRevenue(data),
          currency: getCurrency(data), 
          items: integration === "inntopia" ? buildAnalytics4Products(data, section, event) : buildGoogleTagManagerProducts(event, data),
        },
      };
      break;
    case "add_to_cart":
      gtmObject = {
        event,
        ecommerce: {
          currency: getCurrency(data), 
          value: getTotalRevenue(data),
          items: integration === "inntopia" ? buildAnalytics4Products(data, section, event) : buildGoogleTagManagerProducts(event, data),
        },
      };
      break;
    case "view_cart":
      gtmObject = {
        event,
        ecommerce: {
          currency: getCurrency(data), 
          value: getTotalRevenue(data),
          items: integration === "inntopia" ? buildAnalytics4Products(data, section, event) : buildGoogleTagManagerProducts(event, data),
        },
      };
      break;
    case "remove_from_cart":
      gtmObject = {
        event,
        ecommerce: {
          value: getTotalRevenue(data),
          items: integration === "inntopia" ? buildAnalytics4Products(data, section, event) : buildGoogleTagManagerProducts(event, data),
        },
      };
      break;
    case "begin_checkout":
      gtmObject = {
        event,
        ecommerce: {
          currency: getCurrency(data),
          value: getTotalRevenue(data),
          items: integration === "inntopia" ? buildAnalytics4Products(data, section, event) : buildGoogleTagManagerProducts(event, data),
        },
      };
      break;
    case "add_payment_info":
        gtmObject = {
          event,
          ecommerce: {
            currency: getCurrency(data),
            value: getTotalRevenue(data),
            items: integration === "inntopia" ? buildAnalytics4Products(data, section, event) : buildGoogleTagManagerProducts(event, data),
          },
        };
        break;
    case "purchase":
      gtmObject = {
        event,
        ecommerce: {
          currency: getCurrency(data.products),
          transaction_id: data.id,
          affiliation: CONNECT_CLIENT,
          value: integration === "inntopia" ? getTotalRevenue(data.products) : getRevenue(data.products),
          tax: integration === "inntopia" ? getTotalTaxes(data.products) : getTaxes(data.products),
          shipping: 0,
          items: integration === "inntopia" ? buildAnalytics4Products(data.products, section, event) : buildGoogleTagManagerProducts(event, data.products),
        },
      };
      break;
    case "view_login":
      gtmObject = {
        event,
        uuid: data.uuid,
      };
      break;
    case "attempt_login":
      gtmObject = {
        event,
        uuid: data.uuid,
        email: data.email,
      };
      break;
    case "successful_login":
      gtmObject = {
        event,
        uuid: data.uuid,
        email: data.email,
      };
      break;
    case "view_reset_password":
      gtmObject = {
        event,
        uuid: data.uuid,
      };
      break;
    case "attempt_reset_password":
      gtmObject = {
        event,
        uuid: data.uuid,
      };
      break;
    case "successful_reset_password":
      gtmObject = {
        event,
        uuid: data.uuid,
      };
      break;
    default:
      break;
  }

  if (
    typeof window !== "undefined" &&
    typeof window.dataLayer !== "undefined"
  ) {
    if (gtmObject.ecommerce) {
      window.dataLayer.push({ ecommerce: null });
    }
    window.dataLayer.push(gtmObject);
  }
};
