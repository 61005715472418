import { getSalesId } from './general';

import request from 'src/utils/request';
import { CONNECT } from 'src/utils/environment';
import { getSession } from 'src/utils/localStorage';

export function customerResetPassword ({ email }) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/inntopia/customer/reset`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
        email,
      }
    })
    .then((response) => {
      const data = response.data || null;

      if (data.ok) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/customerResetPassword', err);
      reject(err)
    });
  })
};

export function customerChangePassword ({ temporary, email, password }) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/inntopia/customer/change-password`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
        email,
        password,
        oldpassword: temporary
      }
    })
    .then((response) => {
      const data = response.data || null;

      if (data.ok) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/customerChangePassword', err);
      reject(err)
    });
  })
};
