const NUMBER_OF_MONTHS_IN_A_YEAR = 12;

/**
 * 
 * @param date 
 * @param numberOfYearsToRemove 
 */
export default function subtractYears(date: Date, numberOfYearsToRemove: number): Date {
  const newDate = new Date(date); // Create a new Date object based on the input date
  newDate.setFullYear(date.getFullYear() - numberOfYearsToRemove); // Subtract years from the new Date object

  return newDate; // Return the new Date object
}
