import { getSalesId } from './general';

import request from 'src/utils/request';
import { CONNECT } from 'src/utils/environment';
import { getSession } from 'src/utils/localStorage';
import QueryStringRetriever from 'src/utils/queryStringRetriever';

export function getItinerary () {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/inntopia/cart/itinerary`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
      }
    })
    .then((response) => {
      const data = response.data || null;

      if (data.items) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getItinerary', err);
      reject(err)
    });
  })
};

export function updateItineraryRequirements (itineraryRequirements) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/inntopia/checkout/customer-requirements-itinerary`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
        itineraryRequirements
      }
    })
    .then((response) => {
      const data = response.data || null;

      if (data.ok) {
        resolve(true);
      }

      reject(false);
    })
    .catch(err => {
      console.log('error service/updateItineraryRequirements', err);
      reject(err)
    });
  })
};

export function getItineraryPolicies () {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/inntopia/cart/policies`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
        language: 'en-us'
      }
    })
    .then((response) => {
      const data = response.data || null;

      resolve(data);
    })
    .catch(err => {
      console.log('error service/getItineraryPolicies', err);
      reject(err)
    });
  })
};

export function affirmCheckout () {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/inntopia/affirm/checkouttoken`,
      method: 'POST',
      data: {
          sessionId: session?.uuid,
          salesId: inntopiaSalesId,
          checkoutToken: QueryStringRetriever().checkout_token
      }
    })
    .then((response) => {
      resolve(response);
    })
    .catch(err => {
      console.log('error service/affirmCheckout', err);
      reject(err)
    });
  })
};

export function confirmItinerary (paymentMethod) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/inntopia/checkout/confirm`,
      method: 'POST',
      data: {
          sessionId: session?.uuid,
          salesId: inntopiaSalesId,
          paymentMethod
      }
    })
    .then(response => {
      resolve(response);
    })
    .catch(err => {
      reject(err)
    });
  })
};