/**
 * 
 * @param date 
 * @param numberOfDaysToAdd 
 */
export default function addDays(date: Date, numberOfDaysToAdd: number): Date {
  const newDate = new Date(date.getTime());
  newDate.setDate(newDate.getDate() + numberOfDaysToAdd);
  return newDate;
}
