import { getSalesId } from './general';

import request from 'src/utils/request';
import { CONNECT } from 'src/utils/environment';

export function getYieldViewByMonth ({salesId, productCategory, days, month, year, ageCategory}) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId(salesId);

    request({
      url: `${CONNECT}/yield-view`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        productCategoryId: productCategory,
        days: days,
        startMonth: month,
        startYear: year,
        ageCategory: ageCategory,
      }
    })
    .then((response) => {
      const data = response.data || null;

      if (data.result) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getByMonth', err);
      reject(err)
    });
  })
};

export function getYieldViewByDay ({salesId, productCategory, days, date}) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId(salesId);

    request({
      url: `${CONNECT}/yield-view/day`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        productCategoryId: productCategory,
        days: days,
        date: date,
      }
    })
    .then((response) => {
      const data = response.data || null;

      if (data.result) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getYieldViewByDay', err);
      reject(err)
    });
  })
};