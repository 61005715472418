import { getSalesId } from './general';

import request from 'src/utils/request';
import { CONNECT } from 'src/utils/environment';
import { getSession, setSession, getCartImages, setCartImages } from 'src/utils/localStorage';
import { gtmFunction } from 'src/utils/gtm';

export function getCart () {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/cart`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
        bundle: 1,
        group: 1,
      }
    })
    .then((response) => {
      const data = response.data || null;

      if ('itineraryid' in data) {
        setSession({ ...session, cart: data });
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getCart', err);
      reject(err)
    });
  });
};

export function removeItemFromCart (item, renewCart = true) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/inntopia/cart/remove`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
        bundle: 1,
        group: 1,
        itemId: item.ItineraryItemId
      }
    })
    .then(async (response) => {
      const data = response.data || null;

      if (data === true) {
        if (renewCart) {
          await getCart();
        }
        gtmFunction("remove_from_cart", [{ ...item, Quantity: 1 }], "", "inntopia");
        resolve(true);
      } else {
        reject(null);
      }
    })
    .catch(err => {
      console.log('error service/removeItemFromCart', err);
      reject(err)
    });
  });
};

export function addPackageItemToCart (packageId, itineraryitems, productWithFullInfo) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/inntopia/cart/addPackage`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
        bundle: 1,
        group: 1,
        packageId: packageId,
        itineraryitems: itineraryitems
      }
    })
    .then(async (response) => {
      const currentImages = getCartImages() || [];
      productWithFullInfo.forEach(prod => {
        if (prod.ProductCategoryId === 1 && prod.Image) {
          currentImages.push({ 
            SupplierId: prod.SupplierId,
            ProductId: prod.ProductId,
            Image: prod.Image.FileName 
          });
        }
      });

      setCartImages(currentImages);

      const data = response.data || null;
      gtmFunction("add_to_cart", productWithFullInfo, "", "inntopia");
      if (data.ItineraryId) {
        await getCart();
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/addPackageItemToCart', err);
      reject(err)
    });
  });
};

export function addItemToCart (itineraryitems, productWithFullInfo, pageTitle, noRenewCart = false) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `${CONNECT}/inntopia/cart/add`,
      method: 'POST',
      data: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        sessionId: session?.uuid,
        bundle: 1,
        group: 1,
        itineraryitems: itineraryitems
      }
    })
    .then(async (response) => {
      const data = response.data || null;
      const currentImages = getCartImages() || [];
      currentImages.push({ 
        SupplierId: productWithFullInfo.SupplierId,
        ProductId: productWithFullInfo.ProductId,
        Image: productWithFullInfo.Image ? productWithFullInfo.Image.FileName : ""
      });
      setCartImages(currentImages);
      gtmFunction("add_to_cart", [productWithFullInfo], pageTitle, "inntopia");
      if (data.ItineraryId) {
        if (!noRenewCart) {
          await getCart();
        }

        resolve(data);
      }
      reject(null);
    })
    .catch(err => {
      console.log('error service/addItemToCart', err);
      reject(err)
    });
  });
};

export function addPromoCode (code) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
    url: `${CONNECT}/inntopia/cart/setPromocode`,
    method: 'POST',
    data: {
      id: inntopiaSalesId,
      salesId: inntopiaSalesId,
      sessionId: session?.uuid,
      packageCode: code
    }
    })
    .then(async (response) => {
      const data = response.data || null;

      if ('PackageCode' in data) {
        await getCart();
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/setPromocode', err);
      reject(err)
    });
  });
}

export function saveItinerary(itinerary_id, firstName, lastName, email) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    request({
      url: `https://vvdb2732de.execute-api.us-east-1.amazonaws.com/send`,
      method: 'POST',
      data: {
        sessionid: session?.uuid,
        salesid: inntopiaSalesId,
        itineraryid: itinerary_id,
        firstName,
        lastName,
        email
      }
    })
    .then((response) => {
      const data = response.data || null;
      resolve(data);
      reject(null);
    })
    .catch(err => {
      console.log('error service/saveItinerary', err);
      reject(err)
    });
  });
}