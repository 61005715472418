
/**
 * x
 * @param date1 xx
 * @param date2 x
 */
export default function diffYears(date1: Date, date2: Date): number {
  return Math.abs(date1.getFullYear() - date2.getFullYear());
}


