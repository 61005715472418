
/**
 * @param date 
 * @returns 
 */
 export default function getNextSunday(date: Date = new Date(Date.now())): Date {
  
  const dt = new Date(date.getTime());

  const nextSunday = new Date(
    dt.setDate(dt.getDate() + ((7 - dt.getDay() + 7) % 7 )));

  return nextSunday;

}














