import { getCart } from './cart';
import { getSalesId } from './general';

import request from 'src/utils/request';
import { CONNECT } from 'src/utils/environment';
import { getSession } from 'src/utils/localStorage';

const isValidUpsellingItemList = (input) => {
  const inputStr = String(input);

  if (!isNaN(inputStr.trim()) && inputStr.trim() !== '') {
    return true;
  }

  const numbers = inputStr.split(',');
  const validNumbers = numbers.filter(num => num.trim() !== '' && !isNaN(num));

  return validNumbers.length > 0;
}

export function getBundle (upsellItemList, productBundleId) {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    if (!isValidUpsellingItemList(upsellItemList)) {
      resolve(false);
    }

    request({
      url: `${CONNECT}/inntopia/cart/bundle`,
      method: 'POST',
      data: {
        sessionId: session?.uuid,
        salesId: inntopiaSalesId,
        itineraryItemList: upsellItemList,
        productBundleId: productBundleId ? productBundleId : null
      }
    })
    .then(response => {
      const bundleData = response.data;

      if (!bundleData || (bundleData && bundleData.length === 0)) {
        resolve(false);
      }

      bundleData.map(item => {
        const updatedItem = { ...item };
        updatedItem.Bundles.map(bundle => {
          let updatedBundle = { ...bundle };
          if (updatedBundle.minAddon > 0) {
            updatedBundle.childProducts[0].quantitySelected = updatedBundle.minAddon;
          }
        });
        return updatedItem;
      })
      resolve(response.data);
    })
    .catch(err => reject(err));
  });
}

export const addBundle = ({ itineraryitems, itineraryItemId, productBundleId }) => {
  return new Promise((resolve, reject) => {
    const inntopiaSalesId = getSalesId();
    const session = getSession();

    let params = {
      id: inntopiaSalesId,
      sessionId: session?.uuid,
      salesId: inntopiaSalesId,
    };
    params.itineraryitems = JSON.stringify(itineraryitems);
    params.itineraryItemId = itineraryItemId;
    params.productBundleId = productBundleId;

    request({
      url: `${CONNECT}/inntopia/cart/addBundle`,
      method: 'POST',
      data: params
    })
    .then(async (data) => {
      if (data.status >= 200 && data.status <= 299) {
        const res = data.data;
        await getCart();
        resolve(res);
        // gtmFunction("addToCart", productWithFullInfo, false)
      }
    })
    .catch(() => {
      reject(false);
    });
  });
}