const getCurrencyFromPrice = (item) => {
  if (!item) {
    return '';
  }

  if (Array.isArray(item)) {
    item = item[0];
  }

  if (item.Currency || item.currency) {
    return item.Currency || item.currency;
  }

  if (item.CurrencyAware_Price && item.CurrencyAware_Price.BaseCurrencyCode && item.CurrencyAware_Price.BaseCurrencyCode !== '') {
    return item.CurrencyAware_Price.BaseCurrencyCode;
  }

  if (item.CurrencyAware_PackagePrice && item.CurrencyAware_PackagePrice.BaseCurrencyCode && item.CurrencyAware_PackagePrice.BaseCurrencyCode !== '') {
    return item.CurrencyAware_PackagePrice.BaseCurrencyCode;
  }

  if (item.ProductList) {
    let currency = '';
    item.ProductList.forEach(p => {
      if (p.Currency || p.currency) {
        currency = p.Currency || p.currency;
      }
      if (p.CurrencyAware_Price && p.CurrencyAware_Price.BaseCurrencyCode && p.CurrencyAware_Price.BaseCurrencyCode !== '') {
        currency = p.CurrencyAware_Price.BaseCurrencyCode;
      }
      if (p.CurrencyAware_PackagePrice && p.CurrencyAware_PackagePrice.BaseCurrencyCode && p.CurrencyAware_PackagePrice.BaseCurrencyCode !== '') {
        currency = p.CurrencyAware_PackagePrice.BaseCurrencyCode;
      }
    });
    return currency;
  }

  if (item.Products_Lodging) {
    let currency = '';
    item.Products_Lodging.forEach(p => {
      if (p.Currency || p.currency) {
        currency = p.Currency || p.currency;
      }
      if (p.CurrencyAware_Price && p.CurrencyAware_Price.BaseCurrencyCode && p.CurrencyAware_Price.BaseCurrencyCode !== '') {
        currency = p.CurrencyAware_Price.BaseCurrencyCode;
      }
      if (p.CurrencyAware_PackagePrice && p.CurrencyAware_PackagePrice.BaseCurrencyCode && p.CurrencyAware_PackagePrice.BaseCurrencyCode !== '') {
        currency = p.CurrencyAware_PackagePrice.BaseCurrencyCode;
      }
    });
    return currency;
  }

  return '';
};

export {
  getCurrencyFromPrice,
}