import { getSalesId } from './general';

import request from 'src/utils/request';
import { CONNECT } from 'src/utils/environment';

export function getActivity ({salesId, supplierId, startDate, productSuperCategoryId, notDated}) {
  const inntopiaSalesId = getSalesId(salesId);

  let segment = `merchandise`;
  let parameters = {
    id: inntopiaSalesId,
    salesId: inntopiaSalesId,
    supplierId,
    productSuperCategoryId
  };

  if (!notDated) {
    parameters.startDate = startDate;
    segment = `activities`;
  }

  return new Promise((resolve, reject) => {
    request({
      url: `${CONNECT}/${segment}`,
      method: 'GET',
      params: parameters
    })
    .then((response) => {
      const data = response.data || null;

      if (data.result) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getActivity', err);
      reject(err)
    });
  })
};

export function getActivityDetail ({salesId, supplierId, startDate, productId}) {
  const inntopiaSalesId = getSalesId(salesId);

  return new Promise((resolve, reject) => {
    request({
      url: `${CONNECT}/activities/details`,
      method: 'GET',
      params: {
        id: inntopiaSalesId,
        salesId: inntopiaSalesId,
        supplierId,
        productId,
        startDate
      }
    })
    .then((response) => {
      const data = response.data || null;

      if (data.ProductId) {
        resolve(data);
      }

      reject(null);
    })
    .catch(err => {
      console.log('error service/getActivityDetail', err);
      reject(err)
    });
  })
};
