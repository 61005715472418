import { getCurrencyFromPrice } from "src/modules/commerce/onlineMode/utils/currency";

const buildAnalytics4Products = (prods, section = false, event) => {
    let gtmProducts = [];
    prods.forEach((prod, idx) => {
        let gtmProduct = {}
        if (event === "view_item_list") {
            gtmProduct.item_list_name = `Search Results ${section}`;
        } 

        // if we are before and AddToCart situation, quantity will be 0
        if (event === "view_item_list" || event === "view_item" || event === "select_item") {
            gtmProduct.quantity = 1
        } else {
            gtmProduct.quantity = prod.Quantity || prod.quantity || 1;
        }

        if (prod.PackageName) {
            gtmProduct.item_variant = prod.PackageName;
        }

        gtmProduct.index = idx;
        gtmProduct.item_name = prod.ProductName;
        gtmProduct.item_id = prod.ProductId; 
        gtmProduct.price = getPrice(prod);
        gtmProduct.item_brand = prod.SupplierName;
        gtmProduct.item_category = prod.CategoryName ? prod.CategoryName.toLowerCase() : section;
        gtmProduct.currency = getCurrencyFromPrice(prod);
        gtmProduct.sku = `${prod.SupplierId}-${prod.ProductId}`

        gtmProducts.push(gtmProduct);
    });
  
    return gtmProducts;
};

const getPrice = (product) => {
    // avoid getting prices from API like 598.999999991
    if (product.UnitaryPrice) {
        return product.UnitaryPrice
    }
    if (product.Supplier && product.Supplier.Pricing && product.Supplier.Pricing && product.Supplier.Pricing.price && product.Supplier.Pricing.price.perStay) {
        return Math.round(product.Supplier.Pricing.price.perStay * 100 ) / 100;
    }
    if (product.Pricing && product.Pricing.price && product.Pricing.price.perStay) {
        return Math.round(product.Pricing.price.perStay * 100 ) / 100;
    }
    if (product.FromPrice) {
        return Math.round(product.FromPrice * 100 ) / 100;
    }

    return Math.round(product.Price * 100 ) / 100;
}

const getTotalRevenue = (itinerary) => {
    let totalPrice = 0;
    if (!itinerary) return;
    itinerary.forEach(product => {
        if (product.Price === 0) {
            return;
        }
        
        if (!product.Price) {
            if(product && product.Pricing && product.Pricing.price && product.Pricing.price.perStay) {
                totalPrice += product.Pricing.price.perStay;
            }
        } else {
            totalPrice += parseFloat(product.UnitaryPrice || product.Price) * product.Quantity;
            // removed them as instructed, only in purchase they will appear
            // if (product.TaxesFees) {
            //     totalPrice += parseFloat(product.TaxesFees)
            // }
        }
    })

    return totalPrice;
}

const getTotalTaxes = (itinerary) => {
    let totalTaxes = 0;
    if (!itinerary) return;
    itinerary.forEach(product => {
        if (product.TaxesFees) totalTaxes += parseFloat(product.TaxesFees)
    })

    return totalTaxes;
}

export { getTotalRevenue, buildAnalytics4Products, getTotalTaxes };